import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { Accordion, AccordionDataType } from '@pretto/app/src/Simulation/components/Accordion/Accordion'
import { CoachingCard } from '@pretto/app/src/Simulation/components/CoachingCard/CoachingCard'
import { FeedbackCard } from '@pretto/app/src/Simulation/components/FeedbackCard/FeedbackCard'
import { ListItemSolutions } from '@pretto/app/src/Simulation/components/ListItemSolutions/ListItemSolutions'
import { useTracking } from '@pretto/app/src/lib/tracking'
import { SimulationAnalysisRecommendationLinkKind, SimulationKind } from '@pretto/app/src/types/api/enums'
import {
  SimulationAnalysisFailure,
  SimulationAnalysisRecommendation,
  SimulationAnalysisRecommendationLink,
} from '@pretto/app/src/types/api/schema'
import { SmileyFrown } from '@pretto/picto'

import { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import * as S from './SimulationNonFundableView.styles'

interface SimulationNonFundableViewProps {
  recommendations: SimulationAnalysisRecommendation[] | undefined
  failures: SimulationAnalysisFailure[] | undefined
}

const SimulationNonFundableView = ({ recommendations, failures }: SimulationNonFundableViewProps) => {
  const trackAction = useTracking()
  const { simulation } = useSentences()
  const { push } = useHistory()

  const trackKind = { CAPACITY: 'CAPACITY_ERROR_PAGE_VIEWED', PURCHASE: 'SIMULATION_ERROR_PAGE_VIEWED' } as const

  useEffect(() => {
    const allTags = recommendations?.map(recommendation => recommendation.tag)
    const eventKind = simulation.kind === SimulationKind.Capacity ? trackKind.CAPACITY : trackKind.PURCHASE

    trackAction(eventKind, {
      simulation_error_reco_kind: allTags,
      simulation_error_reco_kind_llm_response: recommendations?.map(recommendation => ({
        title: recommendation.title,
        description: recommendation.description,
      })),
      simulation_error_failure_kind: failures?.map(failure => ({
        title: failure.title,
        description: failure.description,
      })),
    })
  }, [])

  const feedbackList: { [key: string]: string } = { POSITIVE: 'positive', NEUTRAL: 'neutral', WRONG: 'wrong' }

  const handleFeebackClick = (value: 'POSITIVE' | 'NEUTRAL' | 'WRONG') => {
    const feedback = feedbackList[value]
    const trackKind = {
      CAPACITY: 'CAPACITY_ERROR_FEEDBACK_CLICKED',
      PURCHASE: 'SIMULATION_ERROR_FEEDBACK_CLICKED',
    } as const

    const eventKind = simulation.kind === SimulationKind.Capacity ? trackKind.CAPACITY : trackKind.PURCHASE

    trackAction(eventKind, { feedback })
  }

  const handleCoachingCardClick = () => {
    const trackKind = {
      CAPACITY: 'CAPACITY_ERROR_FEE_APPOINTMENT_CLICKED',
      PURCHASE: 'SIMULATION_ERROR_FEE_APPOINTMENT_CLICKED',
    } as const

    if (simulation.kind === SimulationKind.Capacity) {
      trackAction(trackKind.CAPACITY)
    }
    if (simulation.kind === SimulationKind.Purchase) {
      trackAction(trackKind.PURCHASE)
    }
  }

  const handleRecoTrackTypeClick = (type: 'partner' | 'article' | 'cta', recoIndex: number) => {
    const trackKind = { CAPACITY: 'CAPACITY_ERROR_RECO_CLICKED', PURCHASE: 'SIMULATION_ERROR_RECO_CLICKED' } as const
    const eventKind = simulation.kind === SimulationKind.Capacity ? trackKind.CAPACITY : trackKind.PURCHASE
    const prefix = simulation.kind === SimulationKind.Capacity ? 'capacity' : 'simulation'

    trackAction(eventKind, {
      [`${prefix}_error_reco_clicked_cta_type`]: type,
      [`${prefix}_error_reco_clicked_kind`]: recommendations?.[recoIndex]?.tag,
    })
  }

  const handleRecoTrackFailureClick = (failure: AccordionDataType) => {
    const trackKind = { CAPACITY: 'CAPACITY_ERROR_RECO_CLICKED', PURCHASE: 'SIMULATION_ERROR_RECO_CLICKED' } as const
    const eventType = simulation.kind === SimulationKind.Capacity ? trackKind.CAPACITY : trackKind.PURCHASE
    const prefix = simulation.kind === SimulationKind.Capacity ? 'capacity' : 'simulation'

    trackAction(eventType, {
      [`${prefix}_error_failure_clicked_kind`]: {
        title: failure.title,
        description: failure.content,
      },
    })
  }

  const getCurrentDate = useMemo(() => {
    const date = new Date()
    return date.toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric' })
  }, [])

  const mapSimulationAnalysisToAccordionData = (failures: SimulationAnalysisFailure[]) => {
    const data = failures.map(failure => {
      return { title: failure.title, content: failure.description }
    })

    return data
  }

  const mapSimulationAnalysisToListItemSolutionsData = (recommendations: SimulationAnalysisRecommendation[]) => {
    const getRecommendationLinks = (
      links: SimulationAnalysisRecommendationLink[] | undefined,
      kind: SimulationAnalysisRecommendationLinkKind,
      recoIndex: number
    ) => {
      if (!links) return null

      const link = links.find(link => link.kind === kind)
      if (!link) return null

      return {
        content: link.title,
        picture: link.imageUrl.replace(/^https:\/\/res\.cloudinary\.com\/pretto-fr\/image\/upload\//, ''),
        link: link.url,
        onClickRecoTrackType: () => {
          handleRecoTrackTypeClick(
            kind === SimulationAnalysisRecommendationLinkKind.Article ? 'article' : 'partner',
            recoIndex
          )
        },
      }
    }

    const reco = recommendations.map((recommendation, index) => {
      return {
        recommendation: {
          title: recommendation.title,
          content: recommendation.description,
          tag: recommendation.tag,
          cta: recommendation.action
            ? {
                title: recommendation.action.title,
                onCtaClick: () => {
                  if (recommendation.action) {
                    handleRecoTrackTypeClick('cta', index)
                    push(`/project/${`${simulation.kind}`.toLowerCase()}${recommendation.action.path}`)
                  }
                },
              }
            : null,
        },
        article: recommendation.links
          ? getRecommendationLinks(recommendation.links, SimulationAnalysisRecommendationLinkKind.Article, index)
          : null,
        partner: recommendation.links
          ? getRecommendationLinks(recommendation.links, SimulationAnalysisRecommendationLinkKind.Partner, index)
          : null,
      }
    })

    return reco
  }

  const accordionData = mapSimulationAnalysisToAccordionData(failures || [])

  const ListItemSolutionData = mapSimulationAnalysisToListItemSolutionsData(recommendations || [])

  return (
    <S.MainContent>
      <S.MainContentWrapper>
        <S.SectionTop>
          <S.Heading>Vos solutions vers le financement</S.Heading>
          <S.Date>Résultats au {getCurrentDate}</S.Date>
        </S.SectionTop>

        <S.Section>
          <S.Content>
            Malheureusement votre projet ne semble pas finançable par Pretto en l’état. Vous devez améliorer votre
            profil emprunteur ou réévaluer votre projet. On vous explique :
          </S.Content>
          <S.AccordionContainer>
            <Accordion
              data={accordionData}
              picto={<SmileyFrown />}
              withPicto={true}
              onClickTrack={handleRecoTrackFailureClick}
            />
          </S.AccordionContainer>
        </S.Section>
        <S.Section>
          <ListItemSolutions
            title={'Quelles sont vos solutions ?'}
            data={ListItemSolutionData}
            onClickSimu={() => {
              push(`/project/${`${simulation.kind}`.toLowerCase()}/introduction`)
            }}
          />
        </S.Section>

        <S.FeedbackCardContainer>
          <FeedbackCard
            title={
              'Nos recommandations personnalisées sont générées à l’aide d’une intelligence artificielle. Les avez-vous trouvées à la hauteur ?'
            }
            onFeedbackClick={Feedback => handleFeebackClick(Feedback)}
          />
        </S.FeedbackCardContainer>

        <S.CoachingCardContainer>
          <CoachingCard
            title={'Vous êtes déterminé ?'}
            content={
              "<strong>Réservez une consultation</strong> avec l'un de nos experts en financement. Il répondra à vos questions et vous apportera les conseils nécessaires pour réaliser votre projet immobilier"
            }
            ctaTitle={'Ça m’intéresse !'}
            ctaHref={'https://pretto-fr.typeform.com/to/Nm2myYsn'}
            picture={'v1738856705/app/illustrations/handkey.svg'}
            onCtaClick={handleCoachingCardClick}
          />
        </S.CoachingCardContainer>

        <S.Section>
          <S.Caption>
            Un crédit vous engage et doit être remboursé. Vérifiez vos capacités de remboursement avant de vous engager.
          </S.Caption>
        </S.Section>
      </S.MainContentWrapper>
    </S.MainContent>
  )
}

export default SimulationNonFundableView
