import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { useTracking } from '@pretto/app/src/lib/tracking'
import { SimulationKind } from '@pretto/app/src/types/api/enums'

import { useEffect, useState } from 'react'

import * as S from './LoaderLLM.styles'

type item = {
  title: string
}

export interface LoaderLLMProps {
  title: string
  illustration: string
  listItem: item[]
  timing: number // in milliseconds
}

const LoaderLLM: React.FC<React.HTMLAttributes<HTMLDivElement> & LoaderLLMProps> = ({
  title,
  illustration,
  listItem,
  timing,
}) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const trackAction = useTracking()
  const { simulation } = useSentences()

  useEffect(() => {
    const trackKind = {
      CAPACITY: 'CAPACITY_LOADER_NONFUNDABLE_PAGE_VIEWED',
      PURCHASE: 'SIMULATION_LOADER_NONFUNDABLE_PAGE_VIEWED',
    } as const
    const eventKind = simulation.kind === SimulationKind.Capacity ? trackKind.CAPACITY : trackKind.PURCHASE
    trackAction(eventKind)
  }, [])

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveIndex(prevIndex => {
        const nextIndex = (prevIndex + 1) % listItem.length
        return nextIndex
      })
    }, timing || 1000)

    return () => clearInterval(timer)
  }, [listItem.length, timing])

  return (
    <S.Container>
      <S.ImageContainer>
        <S.Image path={illustration} />
      </S.ImageContainer>
      <S.ContentContainer>
        <S.Title>{title}</S.Title>
        <S.List>
          {listItem.map((item, index) => (
            <S.ListItem key={index} isActive={activeIndex === index} isPrevious={index < activeIndex}>
              <S.Content isActive={activeIndex === index} isPrevious={index < activeIndex}>
                {item.title}
              </S.Content>
            </S.ListItem>
          ))}
        </S.List>
      </S.ContentContainer>
    </S.Container>
  )
}

export default LoaderLLM
